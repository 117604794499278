import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Main } from '../../../../constant';
import { Input } from '../../../../components/input/input';
import Auth from './components/auth';
import PageContainer from '../../../../components/Container/pageContainer';
import { Box, Card, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import Logo from '../../../../components/Logo/Logo';

const Otp = (props) => {
    console.log('otp')
    const theme = useTheme();
    const style = theme.palette.Main.login;
    const [isApp, setIsApp] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    function isInStandaloneMode() {
        // Check if the browser is running in standalone mode on iOS
        if (window.navigator.standalone) {
            setIsApp(true);
            return true;
        }
      
        // Check if the browser is running in standalone mode on Android
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsApp(true);
            return true;
        }
      
        // If neither condition is met, return false
        setIsApp(false);
        return false;
    }

    useEffect(() => {
        isInStandaloneMode();
        if (isApp) {
            // Hide install button if the app is already installed
            return;
        }

        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            console.log(event)
            event.preventDefault();
            // Stash the event so it can be triggered later
            setDeferredPrompt(event);
        });
        console.log(deferredPrompt)

    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                // Reset the deferredPrompt
                setDeferredPrompt(null);
            });
        }
    };

    return (
       <>
       <PageContainer  title="Login" description="this is Dashboard">
            <Box  sx={style.mainbox}>
                <Grid container spacing={0} justifyContent="center" sx={{ minHeight: '100vh' }}>
                    <Grid item
                        xs={12}
                        sm={12}
                        lg={4}
                        xl={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Card elevation={9} sx={{ p: 4, zIndex: 1, width: '100%', width: '700px' }}>
                            <Box className="logoScreen" display="flex" alignItems="center" justifyContent="center">
                                <Logo />
                            </Box>
                            <br/>
                            <br/>
                            
                            <Box display="flex" alignItems="center" justifyContent="center">
                            </Box>
                            {
                                isApp === false ? 
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <button onClick={handleInstallClick}>Install Mobile Application</button>
                                    </Box>
                                :
                                <Auth />
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
       </PageContainer>
       </>
    );
};

Otp.propTypes = {};

export default Otp;
