import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'
import { CustomBtn } from '../button/button'
import { PostRequest } from '../../redux/actions/PostRequest'
import StudentUpdateinfo from './StudentUpdateinfo'
import { DateGlobalConvertor } from "./dateGlobalConverter";
import './student.css'
import dayjs from 'dayjs';

const StudentReportComponent = (props) => {
    // const props = {data: null} 
    // props.data = JSON.parse(dmc.data)
    // const getReports = JSON.parse(props.data.report)
    console.log(props)
    const image = useSelector((state)=>state.Api.imageServer)
    // console.log(`${props.data.Picture}`)
    console.log(props)
    
    //  const objData = props.data.report[0].date
    //  console.log(objData)
    // console.log(DateGlobalConvertor(objData))
    // console.log(`${localStorage.getItem('server')}/Public/${props.data.Picture}`)
    const style = useTheme().palette.Components.teacherreoprt;
    const url = useSelector((state)=>state.Api);
    const dispatch = useDispatch();
    const userToken = JSON.parse(localStorage.getItem('User_Data'))?.token || undefined;
    // const imageUrl = JSON.parse(localStorage.getItem('User_Data'))?.imageUrl || undefined;
    const getData = JSON.parse(localStorage.getItem('User_Data'))?.Descript || undefined;
// console.log(getData)
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  

    const func = async(getparam) => {
        setOpen(true);
        // handleClickOpen(getparam)
    }
 

    const handleClose = () => {
        setOpen(false);
    };
    const updateinformation = () => {
        setModal(true);
    }
    const modalClose = () => {
        setModal(false);
    }
    function extractArabic(text) {
        if (text == undefined || text == null || text == '') return ' ';
        const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]+/g;
        const arabicMatches = text.match(arabicRegex);

        const arabicWords = arabicMatches ? arabicMatches : [];   
        console.log(arabicWords) 
        var stringArray = new Array();
        for(var i =0; i < arabicWords.length; i++){
            stringArray.push(arabicWords[i]);
            if(i != arabicWords.length-1){
                stringArray.push(" ");
            }
        }
        return stringArray;
    }
 
  return (
    <>
        <Grid container display={'flex'} justifyContent={'center'} justifyItems={'center'} alignItems={'center'}>
            <Grid item lg={12}>
                <Box p={2}>
                    <Grid container>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Box md={4} mt={1} height='100%'>
                                {
                                    props.data?.Picture != undefined ? 
                                    <>
                                        <Avatar
                                        src={`${getData.imageUrl}${props.data.Picture}`}
                                        sx={style.image }
                                        />
                                    </>
                                    :
                                    <Avatar
                                        src={``}
                                        sx={style.image}
                                        />
                                }

                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                        <Grid container className='pashtoNames'>
                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <Typography variant='p'>
                                    <span className='one'><b>{props.data.RollNum}</b></span><span className='two'>:اساس نمبر</span> 
                                </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <Typography variant='p'>
                                <span className='one'><b>{props.data.Name_P}</b></span><span className='two'>:نوم</span>
                                </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <Typography variant='p'>
                                    <span className='one'><b>{extractArabic(props.data.Class)}</b></span><span className='two'>:صنف</span>
                                </Typography>
                            </Grid>
                            {/* Uncomment this block if needed */}
                            {/* 
                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <Typography variant='p'>
                                    Phone Number: <b>{props.data.TellNo1}, {props.data.TellNo2}</b>
                                </Typography>
                            </Grid> 
                            */}
                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <Typography variant='p'>
                                    <span className='one'><b>{props.data.MonthlyFee}</b></span><span className='two'>:فیس</span>
                                </Typography>
                            </Grid>
                            {
                                props?.data?.reduceamount != ''? 
                                
                            <Grid item lg={12} md={12} sm={6} xs={12} className='dues'>
                                <Typography variant='p'>
                                    <span className='one'><b>{props.data.reduceamount}</b></span><span className='two'>:بقایه</span>
                                </Typography>
                            </Grid>
                        : null
                            }
                            {
                                props?.data?.Discount != '' && props?.data?.Discount != undefined? 
                                <Grid item lg={12} md={12} sm={6} xs={12}>
                                    <Typography variant='p'>
                                        <span className='one'><b>{props.data.Discount}</b></span><span className='two'>:تخفیف</span>
                                    </Typography>
                                </Grid>
                                : null
                            } 
                            <Grid item lg={12} md={12} sm={6} xs={12} className='attend'>
                                <Typography variant='p'>
                                    <span className='zero'><b> دنن ورځ حاضری </b></span>
                                </Typography>
                            </Grid>

                            <Grid item lg={12} md={12} sm={6} xs={12}>
                                <Typography variant='p'>
                                    {props.data.Status == 0 ? 
                                        <>
                                            <span className='one'>{props.data.timeOut}</span><span className='two'>{props.data.timeIn}</span>
                                        </>
                                    :
                                        props.data.Status == 1 ? <span className='two'>غیر حاضر</span> : 
                                        props.data.Status == 2 || props.data.Status == 3 ? <span className='two'>رخصت</span> : null
                                    }
                                </Typography>
                            </Grid>
                             
                                                            
                                {//this is for comment
                                    props.data.report.length > 0 ? 
                                        <>
                                         <span className='reportHead'><b> میاشتنی راپور</b></span>
                                        {props.data.report.map( (rep,i) => (
                                            <Grid className={`report${rep.status}`} key={i} item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant='p'>
                                                    {DateGlobalConvertor(dayjs(rep.date))}
                                                    <br/>
                                                    <b>{rep.comments}</b>
                                                </Typography>
                                            </Grid> 
                                        ))}
                                        </>
                                    : null 
                                }

                                {/* {//this is for Report
                                    getReports != '' ? 
                                    <Grid item lg={12} md={12} sm={12} xs={12} style={{direction: 'rtl'}}>
                                    <Typography variant='p'>
                                        <b>{getReports.comments}</b>
                                    </Typography>
                                    </Grid> 
                                    : null 
                                } */}
{/*                                 
                                <Grid item lg={12} md={12} sm={6} xs={12}>
                                    <Typography variant='p'>
                                        Date Of Brith: <b>{props.data.DOB}</b>
                                    </Typography>
                                </Grid> */}
                                {/* <Grid item lg={12} md={12} sm={6} xs={12}>
                                    <Typography variant='p'>
                                        Admission Date: <b>{props.data.AdmissionDate}</b>
                                    </Typography>
                                </Grid> */}
                                {/* <Grid item lg={12} md={12} sm={6} xs={12}>
                                    <Typography variant='p'>
                                        Address: <b>{props.data.Address} ,  {props.data.Address_P}</b>
                                    </Typography>
                                </Grid> */}
                                {/* <Grid item lg={12} md={12} sm={6} xs={12}>
                                    <Typography variant='p'>
                                        Fees: <b>{props.data.Fees}</b>
                                    </Typography>
                                </Grid> */}

                                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant='p'>
                                        Pashto Address: <b>{props.data.Address_P}</b>
                                    </Typography>
                                </Grid> */}

                                
                                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant='p'>
                                        Phone Number: <b>{props.data.TellNo2}</b>
                                    </Typography>
                                </Grid> */}
                               
                                


                            </Grid>
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
        </Grid>
    </>
  )
}

StudentReportComponent.propTypes = {}

export default StudentReportComponent