
// import { baselightTheme } from "./theme/DefaultColors";
import { Themefunc } from './theme/DefaultColors';
import { CssBaseline, ThemeProvider } from '@mui/material';

import './assets/css/index.scss'
import Popup from './components/popup/popup';
// import { useEffect } from 'react';
// import axios from 'axios'; 
// import { FirstTimeWebSrn } from './hooks/FirstTimeWebSrn/FirstTimeWebSrn';
import Showclasses from './components/showclasses';
import Otp from './veiws/Website/authentication/otp/otp';
import  Home  from './veiws/Website/Home/Home';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './components/loader/loader'
import Wasily from './components/xaoasoft/label';

function App() {
  const veri = useSelector((state) => state.Auth.permission);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  const handleBeforeInstallPrompt = (event) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    console.log('app welcome')
    // Stash the event so it can be triggered later
    setDeferredPrompt(event);
  };


  // const routing = useRoutes(Router);
  const theme = Themefunc();
  Showclasses()
  const [getToken, setGetToken] = useState(localStorage.getItem('User_Data'))
  console.log(getToken  )
  console.log(getToken != '' && getToken != null )

  useEffect(()=>{
    setGetToken(localStorage.getItem('User_Data'))
    console.log('useeFfect');     
  },[localStorage.getItem('User_Data')])
  return (
    <ThemeProvider theme={theme}>
      {/* <FirstTimeWebSrn /> */}
      <Popup />
      <Loader />
      { getToken != '' && getToken != undefined && getToken != null ? 
      <Home />
      :
        <Otp />
      }
    <CssBaseline />
    {/* {routing} */}
      <Wasily />
  </ThemeProvider>
  )
}

export default App;
