import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
// import { CustomTable } from '../../../components/table/SimpleTable'
import  CustomTable  from '../../../components/table/SimpleTable'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from '@mui/material'
import StudentReportComponent from '../../../components/report/student';
import { Loader } from "../../../components/loader/loader";
import { useDispatch, useSelector } from 'react-redux';
import './Home.css'
const Home = (props) => {
    const dispatch = useDispatch();
    const userToken = JSON.parse(localStorage.getItem('User_Data'))?.token || undefined;
    const parameter = JSON.parse(localStorage.getItem('User_Data'))?.Descript || undefined;
    // const url = useSelector((state) => state.Api);
    const url = `${localStorage.getItem('server')}/apk/showFess`;
    console.log(parameter)
    const [data , setData ] = useState()
    const [schoolInfo , setSchoolInfo ] = useState()

    function refreshPage(){
      callAPI()
    }
    function callAPI(){
      dispatch({ type: "SHOW_LOADER", Seconds: "1" });
        axios.post(url,{parameter},{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            dispatch({ type: "SHOW_LOADER", Seconds: "0" });
            if (res.status === 200) {
              console.log(res.data[0].schoolDetails);
              setData(res.data)
              setSchoolInfo(res.data[0].schoolDetails)
              console.log(schoolInfo)
            }
          })
          .catch((err) => {
            console.error(err); // Log the error response for debugging
          });
    }
    useEffect(()=>{
        callAPI()

    },[])
return (
    <>
    <Box >
      <Box mt={1} lg={12} justifyContent={'center'} justifyItems={'center'} display={'flex'}> <img width={'50%'} height={'50%'} style={{maxWidth:'150px'}} src={`${parameter.imageUrl}${parameter.WebSrn}/Logo.png`} /> </Box>
    </Box>
      {
        data && data?.length > 0 ?
          <Box mt={2} >
            <Grid item lg={12} ><Box className='announce'>{data[0]?.result?.announcement[0]?.Message}</Box></Grid>
          </Box>
          : null
      }

      {
        data && (
          data.map((item, ind) => (
            <Box display={'flex'} justifyContent={'center'} justifyItems={'center'} alignItems={'center'} key={ind}>
              <StudentReportComponent data={item.result} />
            </Box>
          )))
      }

    <Box >
      <Box mt={1} lg={12} justifyContent={'center'} justifyItems={'center'} display={'flex'}> 
        <button className="primary" onClick={refreshPage} >Refresh</button>      
      </Box>
      <br/>
    </Box>

    <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} alignItems="center" justifyContent="center">
  {/* First Column */}
  
  {/* Second Column */}
  <Box mt={1} lg={12} display="flex" justifyContent="center">
    {/* Phone Numbers */}
    <div>
      <div>  Call Us: <a href={`tel:${schoolInfo?.normalPhone}`}>{schoolInfo?.normalPhone}</a></div>
      <div> WhatsApp: <a href={`whatsapp://send?phone=+93${schoolInfo?.whatsApp}`}>{schoolInfo?.whatsApp}</a></div>
    </div>
  </Box>
  <Box mt={1} lg={12} display="flex" justifyContent="center">
    {/* Google Location */}
    <iframe
      title="Google Location"
      width="100%"
      height="70%"
      frameBorder="0"
      style={{ border: 0 }}
      src={`${schoolInfo?.gLocation}`}
    ></iframe>
  </Box>
</Box>
<br/>
    
    </>
  )
}

Home.propTypes = {}

export default Home