import React from 'react'
import PropTypes from 'prop-types'

const Wasily = (props) => {
    const getDate  = new Date().getFullYear();
    return (
        <footer id="sitefooter" className="aux-site-footer">
            <div className="aux-wrapper aux-desktop">
                <div className="aux-container aux-fold aux-float-wrapper">
                    <div id="copyright" className="aux-copyright aux-middle "> دا اپلیکیشن د  <a href="http://www.wasily.net">واصلي ټیکنالوژي</a> لخواجوړ سوی دی .<br/> تماس 0703131865  
                    </div>
                    <section className="widget-socials aux-socials-container aux-end aux-middle  aux-tablet-off aux-phone-off aux-socials-footer aux-horizontal aux-small">
                    </section>
                </div>
            </div>
        </footer>
    )
}

Wasily.propTypes = {}

export default Wasily